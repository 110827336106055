import React from 'react';

const Header2: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <section className="text-center my-12">
        <h1 className="text-4xl font-bold text-gray-900">EV Charging Solutions</h1>
        <p className="text-xl mt-4 text-gray-600">Leading Electric Vehicle Charging Services in New York and New Jersey</p>
      </section>

      <section className="my-12 p-6 bg-gray-100 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold text-gray-900">Complete EV Charger Installations</h2>
        <p className="mt-4 text-gray-600">We provide end-to-end EV charger installation services, from initial assessment to the final setup, ensuring a smooth transition to electric vehicle infrastructure. Servicing New York, New Jersey, and nearby states.</p>
      </section>

      <section className="my-12 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold text-gray-900">Permits and Rebates Handling</h2>
        <p className="mt-4 text-gray-600">Navigate the complexities of permits and maximize rebates with our expert assistance. We take care of all the paperwork and ensure you get the best incentives available in your region.</p>
      </section>

      <section className="my-12 p-6 bg-gray-100 rounded-lg shadow-md">
        <h2 className="text-3xl font-bold text-gray-900">EV Charging Software Solutions</h2>
        <p className="mt-4 text-gray-600">Optimize your EV charging experience with our advanced software solutions. Monitor and manage your charging stations efficiently with our user-friendly software, designed to keep you in control.</p>
      </section>

      <section className="my-12 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-3xl font-bold text-gray-900">Expert Charger Selection Guidance</h2>
        <p className="mt-4 text-gray-600">Choosing the right charger is crucial. Our experts will help you select the perfect charger that suits your specific needs and budget, considering the latest technologies and your vehicle requirements.</p>
      </section>
    </div>
  );
};

export default Header2;
