import React from 'react';

const Header: React.FC = () => (
  <header className="bg-blue-700 text-white text-center p-6 md:p-12">
    <h1 className="text-3xl md:text-5xl font-bold">Comprehensive EV Charging Solutions</h1>
    <p className="mt-4 text-xl md:text-2xl">
      Your one-stop shop for seamless electric vehicle charging infrastructure—from selection to implementation and beyond.
    </p>
    <div className="mt-6 text-left max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
      <div>
        <h2 className="text-xl font-semibold">Cradle to Grave Services:</h2>
        <ul className="list-disc list-inside">
          <li>Charger Selection Expertise</li>
          <li>Custom Software Solutions</li>
          <li>Comprehensive Civil Work</li>
        </ul>
        </div>
      <div>
        <ul className="list-disc list-inside">
          <li>Professional Electrical Installations</li>
          <li>Permit Management</li>
          <li>Rebate Optimization</li>
        </ul>
      </div>
    </div>
  </header>
);

export default Header;
