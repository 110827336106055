import React from 'react';

const ChargingBrands: React.FC = () => {
  const brands = [
    {
      name: "ChargePoint",
      description: "One of the largest EV charging networks, offering a wide range of integrated solutions spanning home, public, and workplace charging."
    },
    {
      name: "EverCharge",
      description: "Specializes in providing scalable EV charging solutions for multi-tenant buildings, enhancing accessibility for residents."
    },
    {
      name: "RAB",
      description: "Known for energy-efficient lighting, RAB also offers high-quality EV charging solutions geared towards sustainability."
    },
    {
      name: "Tesla",
      description: "Offers advanced EV charging technology with its Supercharger network, designed exclusively for Tesla vehicles."
    },
    {
      name: "Leviton",
      description: "A leading electrical wiring equipment brand that provides reliable home EV charging stations."
    },
    {
      name: "Generac",
      description: "Originally a generator manufacturer, now expanding into clean energy solutions including EV chargers."
    }
  ];

  return (
    <div className="p-6 bg-black">
      <h2 className="text-3xl font-bold text-green-500 text-center mb-6">Major EV Charging Brands</h2>
      <h2 className="text-2xl font-bold text-green-500 text-center mb-6">We will install any brand!</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {brands.map((brand, index) => (
          <div key={index} className="p-4 bg-gray-100 shadow-md rounded-lg">
            <h3 className="font-semibold text-xl">{brand.name}</h3>
            <p className="mt-2 text-gray-600">{brand.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChargingBrands;
