import React from 'react';

const Header: React.FC = () => (
    <header className="bg-black text-green-500 p-8 text-center shadow-lg">
    <h1 className="text-4xl font-bold">EV Charging Solutions</h1>
    <p className="text-xl mt-2">Your trusted partner for comprehensive EV charging infrastructure across the US.</p>
    <div className="mt-4">
        <p className="text-lg">Contact Us:</p>
        <a href="mailto:info@evcolony.com" className="hover:text-green-200">info@evcolony.com</a>
        <br />
        <a href="tel:+16313064602" className="hover:text-green-200">(631) 306-4602</a>
    </div>
</header>


);

export default Header;
