import React from 'react';

// Define the incentives for selected states
const statesIncentives = {
  "New York": "New York offers various incentives, including rebates for both public and private EV charging station installations. These incentives are designed to support the deployment of EV infrastructure across the state.",
  "New Jersey": "New Jersey provides significant tax credits, grants, and rebate programs for EV charger installations. These financial incentives help reduce the initial cost of setting up new charging stations.",
  "Vermont": "Vermont offers rebates through state programs aimed at increasing the number of electric vehicles and chargers. These initiatives are part of Vermont's commitment to environmental sustainability.</p>",
  "Pennsylvania": "Pennsylvania provides tax credits for the installation of electric vehicle charging stations. These incentives encourage both businesses and homeowners to invest in EV technology.</p>",
  "Washington D.C.": "Washington D.C. offers various programs providing rebates for residential, commercial, and public EV charging infrastructure, enhancing the capital's green initiatives.</p>",
  "Delaware": "Delaware offers grants and rebates for EV charging station equipment and installation, facilitating the growth of eco-friendly transportation options."
};

const StatesIncentives: React.FC = () => {
  return (
    <div className="p-5 bg-gray-100">
      <h2 className="text-center text-2xl font-bold mb-5">EV Charger Incentives Across Selected States</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        {Object.entries(statesIncentives).map(([state, description]) => (
          <div key={state} className="p-4 bg-white shadow-md rounded-lg flex flex-col items-center justify-between">
            <h3 className="font-semibold">{state}</h3>
            <p className="text-sm text-gray-600">{description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatesIncentives;
