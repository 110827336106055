import React from 'react';
import ChargingCalculator from './components/ChargingCalculator';
import Header from './components/Header';
import Footer from './components/Footer';
import MainPage from './components/MainPage';
import Header2 from './components/Header2';
import StatesIncentives from './components/StateIncentives';
import ChargingBrands from './components/ChargingBrands';
import EVChargerBenefits from './components/EVChargerBenefits';


function App() {
  const statesRates = {
    NY: 0.21, // example rate per kWh
    NJ: 0.19,
    CT: 0.20,
    PA: 0.18
  };

  return (
    <div className="App">
      <Header />
      <MainPage />
      <StatesIncentives />
      <ChargingBrands />
      <Header2 />
      <EVChargerBenefits />
      <Footer />
    </div>
  );
}

export default App;
