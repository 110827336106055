import React from 'react';

const EVChargerBenefits: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 my-12 bg-black">
      <h1 className="text-center text-4xl font-bold text-green-600 my-6">The Benefits of Installing an EV Charger</h1>
      <div className="space-y-8">

        <section className="bg-gray-50 p-6 rounded-lg shadow">
          <h2 className="text-3xl font-bold text-green-700">For Homeowners</h2>
          <div className="space-y-4 mt-4 text-gray-800">
            <h3 className="text-2xl font-semibold text-black">Convenience and Accessibility</h3>
            <p>Installing an EV charger at home provides unmatched convenience. Say goodbye to detours to public charging stations; wake up every morning to a fully charged vehicle ready for the day.</p>
            
            <h3 className="text-2xl font-semibold text-black">Increase Property Value</h3>
            <p>Homes equipped with EV charging stations are seen as high-value properties in today’s real estate market. This amenity attracts eco-conscious buyers, potentially increasing your home's resale value.</p>
            
            <h3 className="text-2xl font-semibold text-black">Reduced Energy Costs</h3>
            <p>Charging your vehicle during off-peak hours can significantly reduce electricity costs. Modern EV chargers can be programmed to optimize charging times, leveraging lower rates and reducing overall energy bills.</p>
          </div>
        </section>

        <section className="bg-gray-100 p-6 rounded-lg shadow">
          <h2 className="text-3xl font-bold text-green-700">For Businesses</h2>
          <div className="space-y-4 mt-4 text-gray-800">
            <h3 className="text-2xl font-semibold text-black">Attract Eco-Conscious Customers</h3>
            <p>Businesses with EV charging stations attract environmentally aware customers. Providing EV charging can set your business apart, making it a preferred destination for a growing demographic of EV owners.</p>
            
            <h3 className="text-2xl font-semibold text-black">Employee Benefits and Satisfaction</h3>
            <p>Offer your employees the convenience of charging their EVs at work. This not only enhances employee satisfaction but can also serve as an incentive to attract top talent who are looking for environmentally responsible employers.</p>
            
            <h3 className="text-2xl font-semibold text-black">Government Incentives and Rebates</h3>
            <p>Many governments offer incentives for installing EV charging stations, such as tax credits, rebates, and grants. These can significantly offset installation costs and provide ongoing benefits for your business.</p>
          </div>
        </section>

        <section className="bg-gray-50 p-6 rounded-lg shadow">
          <h2 className="text-3xl font-bold text-green-700">Environmental Impact</h2>
          <div className="space-y-4 mt-4 text-gray-800">
            <h3 className="text-2xl font-semibold text-black">Reducing Carbon Footprint</h3>
            <p>By facilitating the use of electric vehicles, both homeowners and businesses can play a crucial role in reducing the carbon footprint associated with transportation, directly contributing to a cleaner environment.</p>
            
            <h3 className="text-2xl font-semibold text-black">Supporting Renewable Energy</h3>
            <p>EV chargers can be integrated with renewable energy sources like solar power, further enhancing environmental benefits and reducing reliance on non-renewable power sources.</p>
          </div>
        </section>

        <section className="bg-gray-100 p-6 py-6 rounded-lg shadow">
          <h2 className="text-3xl font-bold text-green-700">Conclusion</h2>
          <p className="mt-4 text-gray-800">As the world moves towards more sustainable transportation options, investing in EV charging infrastructure is a smart choice. Whether at home or for your business, an EV charger is an investment in the future.</p>
        </section>

      </div>
    </div>
  );
};

export default EVChargerBenefits;
