import React from 'react';

const Footer: React.FC = () => (
  <footer className="bg-gray-800 text-white text-center p-4 mt-8">
    <div className="space-y-2">
      <p className="font-semibold">Contact Us:</p>
      <a href="mailto:info@evcolony.com" className="text-blue-300 hover:text-blue-500">info@evcolony.com</a>
      <p>
      <a href="tel:(631) 306-4602" className="text-blue-300 hover:text-blue-500">(631) 306-4602</a>
      </p>
      {/* <p className="mt-4">
        <a href="/partners" className="text-blue-300 hover:text-blue-500">Our Partners</a>
      </p> */}
      <p>© 2024 EV Charging Solutions. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
